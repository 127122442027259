import './App.css';

function App() {
  return (
    <div className="wrapper">
       <h2>Consistency is 🔑</h2>
    </div>
  );
}

export default App;
